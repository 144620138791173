export const headline = 'Embrace the Colors of Your Being and Feelings';

export const subheadline =
	'Explorează-ți identitatea și personalizează-ți tabloul!';

export const description =
	'Self Posters te îndrumă să te reconectezi cu tine însuți prin personalizarea tablourilor BODY, MIND și SOUL. Îți oferim ghidare în explorarea sentimentelor tale, apoi te ajutăm să le exprimi vizual prin culori, astfel încât să creezi un tablou care te reprezintă cu adevărat.';
export const benefits = [
	'Print personalizat canvas 100% bumbac',
	'Rame din lemn natural certificat FSC® 100%',
	'Montare pe cadru de lemn',
];
