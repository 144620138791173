const benefits = [
	{
		title: 'ELEVATE YOUR WALLS',
		desc: 'Adaugă personalitate și culoare casei tale cu selecția noastră de tablouri printate pe canvas.',
	},
	{
		title: 'SENSE THE QUALITY',
		desc: 'Folosim canvas 100% bumbac, un material ce garantează calitatea și durabilitatea produsului.',
	},
	{
		title: 'FEELINGS ON CANVAS',
		desc: 'Explorează-ți sinele și formează-ți un spațiu care îți reflectă identitatea și construiește-ți tabloul în relație cu sufletul, corpul și mintea ta.',
	},
];

export default benefits;
